@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Roboto&display=swap');


:root {
  --primary: #286D6E;
  --primary-light: #F0F0F0;
  --secondary: #F89E2C;
  --text: #000000;
  --text-light: #FFFFFF;
  --text-secondary: #333333;
  --text-tertiary: #686767;
  --shadow: #ababab;
}

html, body, .container, #root {
  /* height: 100%; */
  min-height: 100%;
}

html {
  position: relative;
  min-width: 250px;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--text-light);
}

.container {
  background-color: var(--primary);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.header {
  width: 100%;
  flex: 0 0 120px;
  box-sizing: border-box;
  padding: 16px;
  background-color: var(--primary);

  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.header:hover {
  cursor: pointer;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  color: var(--text-light);
  margin: 0px 0px 4px 0px;
}

.subtitle {
  font-size: 12px;
  color: var(--text-light);
}

.content {
  margin-top: 100px;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 16px 16px 0px 0px;
  padding: 16px 16px 80px 16px ;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.content-container {
  width: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.year {
  width: 100%;
  box-sizing: border-box;
  margin: 16px 0px 0px 32px;
  color: var(--text-secondary);
}

.month {
  width: 100%;
  box-sizing: border-box;
  background-color: var(--primary-light);
  color: var(--primary);
  padding: 16px 16px;
  margin: 8px 8px;
  border-radius: 8px;
  transition: all 0.1s ease-in-out;
}

.month:hover {
  cursor: pointer;
}

.month:active {
  box-shadow: 0 2px 8px 0 var(--shadow);
}

.agenda {
  width: 100%;
  box-sizing: border-box;
  /* background-color: var(--primary-light); */
  margin: 0px 0px 24px 0px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.agenda-date-box {
  height: 60px;
  flex: 0 0 60px;
  background-color: var(--secondary);
  color: var(--text-light);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 8px;
  margin: 0px 16px 0px 0px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.agenda-day-sm {
  font-size: 9px;
}

.agenda-day {
  font-size: 12px;
}

.agenda-date {
  font-size: 28px;
}

.agenda-month {
  font-size: 12px;
}

.agenda-date-begin {
  font-size: 12px;
}

.agenda-date-end {
  font-size: 12px;
}

.agenda-date-divider {
  border-top: 1px solid white;
  width: 24px;
  margin: 2px 0px 3px 0px;
}

.agenda-date-divider-md {
  border-top: 1px solid white;
  width: 24px;
  margin: 7px 0px 8px 0px;
}

.agenda-desc-box {
  /* background-color: bisque; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.agenda-desc {
  color: var(--text-secondary);
  font-size: 16px;
  line-height: 24px;
}

.agenda-note {
  color: var(--text-tertiary);
  font-size: 12px;
  line-height: 18px;
}

.fab {
  width: 48px;
  height: 48px;
  background-color: var(--primary);
  border-radius: 50%;
  transition: all 0.1s ease-in-out;

  font-size: 32px;
  color: var(--text-light);
  text-align: center;
  line-height: 42px;

  position: fixed;
  right: 24px;
  bottom: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fab:hover {
  cursor: pointer;
}
 
.fab:active {
  box-shadow: 0 4px 12px 0 var(--shadow);
}

.fab > img {
  width: 24px;
}

/* Helpers */
.mb-24 {
  margin-bottom: 24px;
}
